ss<template>
    <div class="view pa24">
        <div class="d-flex">
            <div>
                <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
            </div>
            <div style="margin-left: auto">
                <el-button class="ma" type="primary" @click="showAddModel">添加套餐</el-button>
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="goodPhoto" align="center" label="商品图片">
                    <template slot-scope="scope">
                        <el-image v-if="scope.row.goodPhoto" style="width: 100px; height: 80px;margin-bottom:-10px"
                            :src="scope.row.goodPhoto.split(',')[0]" :preview-src-list="scope.row.goodPhoto.split(',')">
                        </el-image>
                        <p v-else style="width: 100px; height: 80px;line-height: 80px;">
                            暂无图片
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsName" align="center" label="商品名称" show-overflow-tooltip />
                <el-table-column prop="price" align="center" label="价格" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.price | getPrice }}
                    </template>
                </el-table-column>
                <el-table-column prop="levelName" align="center" label="会员等级" show-overflow-tooltip />
                <el-table-column align="center" label="操作" width="80">
                    <template slot-scope="scope">
                        <customPopconfirm confirm-button-text="确定" cancel-button-text="取消"
                            @confirm="disassociate(scope.row)" icon="el-icon-info" title="确定要取消这条商品关联？">
                            <el-button slot="reference" style="color: #F56C6C" type="text">取消关联</el-button>
                        </customPopconfirm>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="添加套餐" :visible.sync="showModel" :destroy-on-close="true"
            width="350px" center>
            <el-form :model="formData" :rules="rules" ref="formData" label-width="78px">
                <el-form-item label="会员等级" prop="levelId">
                    <el-select v-model="formData.levelId" filterable remote placeholder="请输入关键词"
                        :remote-method="selectAllMemberLevel" :loading="selectLoading">
                        <el-option v-for="item in memberLevel" :key="item.levelId" :label="item.levelName"
                            :value="item.levelId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商品" required prop="goodsId">
                    <div class="flex-a-c">
                        <div v-if="productName" class="text-overflow w120 mr10">{{ productName }}</div>
                        <el-button type="primary" @click="showProductModel">选择商品</el-button>
                    </div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="relation">关联等级</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="选择社团商品" :visible.sync="showProduct" :destroy-on-close="true"
            width="80%" center>
            <commonTable :tableData="productTableData" :loading="productLoading"
                @handleSizeChange="productHandleSizeChange" @handleCurrentChange="productHandleCurrentChange"
                :currentPage="productCurrentPage" :total="productTotal">
                <template v-slot:table>
                    <el-table-column width="55">
                        <template slot-scope="scope">
                            <el-radio class="ml10" v-model="temporaryFormData.goodsId" :label="scope.row.goodsId"
                                @change="selectProduct(scope.row)">
                                {{ ' ' }}
                            </el-radio>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goodPhoto" align="center" label="商品图片">
                        <template slot-scope="scope">
                            <el-image v-if="scope.row.goodPhoto" style="width: 100px; height: 80px;margin-bottom:-10px"
                                :src="scope.row.goodPhoto"></el-image>
                            <p v-else style="width: 100px; height: 80px;line-height: 80px;">
                                暂无图片
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goodsName" align="center" label="商品名称" show-overflow-tooltip />
                    <el-table-column prop="price" align="center" label="价格" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.price | getPrice }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="stockNum" align="center" label="库存" show-overflow-tooltip />
                </template>
            </commonTable>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="checkedProduct">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import { selectAllMemberLevel } from "@/api/member";
import {
    getGoodsListByLink,
    getGoodsMemberLinkList,
    linkGoodsAndMember,
    delGoodsMemberLink,
} from "@/api/associationMember";
export default {
    name: "groupingTable",
    components: {
        commonTable,
        customPopconfirm
    },
    data() {
        return {
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            //模态框配置
            temporaryFormData: {
                goodsId: 0,
                goodsName: "",
                price: 0
            },
            productName: "",
            formData: {
                price: "",
                accountType: 9,
                levelId: "",//会员等级
                goodsId: 0,//商品id
            },
            rules: {
                levelId: {
                    required: true,
                    trigger: "blur",
                    message: "请选择会员等级",
                },
                goodsId: {
                    required: true,
                    message: "请选择商品",
                },
            },
            formType: "",
            updateId: 0,
            selectLoading: false,
            //显示商品列表
            memberLevel: [],
            productTableData: [],
            showProduct: false,
            productCurrentPage: 1, //当前页
            productPageSize: 10, //显示条数
            productLoading: false, //表格加载
            productTotal: 0, //总条数
            associationId: 0,
        };
    },
    filters: {
        getPrice(price) {
            return Number(price / 100).toFixed(2) + "元"
        }
    },
    computed: {
        watchAssociation() {
            return this.$store.state.checkedAssociation
        }
    },
    watch: {
        watchAssociation(row) {
            this.associationId = row.associationId;
            this.currentPage = 1;
            this.getProductList();
        },
    },
    created() {
        this.associationId = JSON.parse(sessionStorage.getItem("checkedAssociation")).associationId;
        this.selectAllMemberLevel();
        this.getList();
    },
    methods: {
        /**@method 取消商品关联 */
        disassociate(row) {
            delGoodsMemberLink({ linkId: row.linkId }).then(res => {
                if (res.code === 200) {
                    this.getList();
                } else {
                    this.$message(res.message)
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message(err.message)
                }
            })
        },
        /**@method 获取会员等级 */
        selectAllMemberLevel(name) {
            this.selectLoading = true;
            selectAllMemberLevel({
                companyId: this.userInfo.companyId,
                levelName: name,
                pageNum: 1,
                pageSize: 30
            }, 9).then(res => {
                this.selectLoading = false;
                if (res.data) {
                    this.memberLevel = res.data.pageInfo.list;
                }
            }).catch(err => {
                this.selectLoading = false;
            })
        },
        /**@method 选择商品 */
        selectProduct(data) {
            this.temporaryFormData.productName = data.goodsName;
            this.temporaryFormData.goodsId = data.goodsId;
            this.temporaryFormData.price = data.price;
        },
        /**@method 选中商品 */
        checkedProduct() {
            this.productName = this.temporaryFormData.productName;
            this.formData.goodsId = this.temporaryFormData.goodsId;
            this.formData.price = this.temporaryFormData.price;
            this.showProduct = false;
        },
        /**@method 显示社团商品列表 */
        showProductModel() {
            this.showProduct = true;
            this.getProductList();
        },
        /**@method 商品获取列表 */
        async getProductList(payload) {
            let params = {
                pageSize: this.productPageSize,
                pageNum: this.productCurrentPage,
                accountType: 9,
                associationId: this.associationId
            };
            try {
                this.productLoading = true;
                let res = await getGoodsListByLink(params)
                this.productLoading = false;

                const { data } = res;
                this.productTableData = data.pageInfo.list;
                this.productTotal = data.pageInfo.total;
            } catch (error) {
                this.productLoading = false;
                this.productTableData = [];
            }
        },
        /**@method 商品切换行 */
        productHandleSizeChange(val) {
            this.productPageSize = val;
            this.getProductList();
        },
        /**@method 商品切换下一页 */
        productHandleCurrentChange(val) {
            this.productCurrentPage = val;
            this.getProductList();
        },

        /**@method 搜索 */
        searchFun() {
            this.currentPage=1;
            this.getList();
        },
        /**@method 获取列表 */
        async getList(payload) {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                accountType: 9
            };
            try {
                this.loading = true;
                let res = await getGoodsMemberLinkList(params)
                this.loading = false;

                const { data } = res;
                this.tableData = data.pageInfo.list;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        showAddModel() {
            this.showModel = true;
            this.formType = "add";
            this.updateId = 0;
            this.temporaryFormData = {
                goodsId: 0,
                goodsName: "",
                price: 0,
            };
            this.productName = "",
                this.formData = {
                    price: "",
                    accountType: 9,
                    levelId: "",//会员等级
                    goodsId: 0,//商品id
                };
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        showUpdateModel(row) {
            this.showModel = true;
            this.formType = "update";
            this.updateId = row.id;
            this.temporaryFormData = {
                goodsId: row.levelId,
                goodsName: row.goodsName,
                price: row.price
            };
            this.productName = row.goodsName,
                this.formData = {
                    price: row.price,
                    accountType: row.accountType,
                    levelId: row.levelId,//会员等级
                    goodsId: row.goodsId,//商品id
                };
        },
        /**@method 关联 */
        relation() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    if (!this.formData.goodsId) {
                        this.$message({
                            type: "warning",
                            message: "请选择商品"
                        })
                        return;
                    }
                }
                linkGoodsAndMember(this.formData).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            type: "success",
                            message: "关联成功"
                        })
                        this.showModel = false;
                        this.getList();
                    } else {
                        this.$message.error(res.message)
                    }
                }).catch(err => {
                    if (err.code === 201) {
                        this.$message.error(err.message)
                    }
                })
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>